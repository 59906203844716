import React, { useEffect, useRef, useState } from 'react';
import { FiFilter } from 'react-icons/fi';
import Zoom from 'react-medium-image-zoom';
import { Link as ReactRouterLink } from 'react-router-dom';

import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  IconButton,
  Image,
  Link as ChakraLink,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import ChatSkeletons from '../utils/skeletons.jsx/ChatSkeletons';
import FiltersChats from './FiltersChats';

const ChatList = ({
  messages,
  isLoading,
  error,
  handleSelectChange,
  handleSearchLocation,
  selectedOptions,
  chatName,
}) => {
  const bgColor = useColorModeValue('rgba(166, 212, 111, 0.4)');
  const [selectedImage, setSelectedImage] = useState(null);
  const [showAnothersFilters, setShowAnothersFilters] = useState(false);
  const lastMessageRef = useRef(null);

  useEffect(() => {
    if (messages?.messages?.length === 0) return;

    lastMessageRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);
  const responsiveBase = selectedOptions?.date ? '190px' : '130px';
  if (isLoading) return <ChatSkeletons />;
  return (
    <Box m={0} mt={2} width={'100%'} height={'100%'}>
      <Box
        border="1px"
        height={'100%'}
        borderColor={'gray.200'}
        mt={0}
        pt={0}
        pl={0}
        bg="blue.50"
        color="white"
        overflowY={'hidden'}
        width={'100%'}
        boxShadow={'lg'}
        mb={2}
      >
        <Box
          pt={'25px'}
          position={'sticky'}
          top={0}
          h={showAnothersFilters ? { base: responsiveBase, xl: '130px' } : '90px'}
          // overflowY={{ base: 'auto ', md: 'none', xl: 'none' }}
          borderBottom={'1px'}
          display={'flex'}
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          flexDirection={'column'}
          px={2}
          pb={3}
          bg="white"
          borderBottomWidth={2}
          borderColor={'gray.200'}
        >
          <Flex alignItems={'center'} justifyContent={'space-between'} w={'100%'} mb={1} pt={4}>
            <Text color="gray.900" fontSize="xl" fontWeight="bold" m={0}>
              {messages?.chat_name || chatName}
            </Text>
            <Button
              type="button"
              variant={'ghost'}
              m={0}
              size="sm"
              onClick={() => setShowAnothersFilters(!showAnothersFilters)}
            >
              <FiFilter
                style={{
                  fontSize: '20px',
                  color: showAnothersFilters ? 'orange' : '#2C5282',
                }}
              />
            </Button>
          </Flex>
          {showAnothersFilters && (
            <FiltersChats
              handleSelectChange={handleSelectChange}
              handleSearchLocation={handleSearchLocation}
              selectedOptions={selectedOptions}
            />
          )}
        </Box>
        {selectedImage ? (
          <Box>
            <Box display={'flex'} justifyContent={'flex-end'} mb={1}>
              <IconButton
                color="white"
                backgroundColor="red.500"
                fontWeight="bold"
                fontSize={16}
                onClick={() => setSelectedImage(null)}
                icon={<CloseIcon />}
              />
            </Box>
            <Zoom defaultZoomLevel={2}>
              <Image src={selectedImage} alt="Expanded Image" maxW="100%" maxH="100%" />
            </Zoom>
          </Box>
        ) : !error && messages?.messages?.length > 0 ? (
          <Box
            height={'80vh'}
            overflowY={'auto'}
            width={'100%'}
            sx={{
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              scrollBehavior: 'smooth',
            }}
          >
            {messages?.messages?.map((message, index) => {
              return (
                <Box px={8} width={'100%'} key={index} pb={4}>
                  <Text color="gray.900" textAlign="center" fontSize="14px" my={1}>
                    {message?.date}
                  </Text>
                  {message?.messages.map((message, index) => {
                    let urlRegex = /(https?:\/\/[^\s]+)/g;
                    let urlMessage = message?.caption?.match(urlRegex)[0];
                    let textCaption = urlMessage && message?.caption.replace(urlMessage, '').trim();
                    return (
                      <Flex
                        key={index}
                        direction={message.pushname === 'user' ? 'row-reverse' : 'row'}
                        align="flex-end"
                        mt={2}
                        width={'80%'}
                      >
                        <Box
                          w={30}
                          h={30}
                          bg="green.500"
                          justifyContent={'center'}
                          align="center"
                          rounded={'100%'}
                          mb={-3}
                          mr={1}
                        >
                          <Text
                            m={0}
                            fontSize="sm"
                            fontWeight="bold"
                            textAlign={'center'}
                            mt={1}
                            color="green.300"
                            w={30}
                            h={30}
                          >
                            {message?.pushname
                              ? message?.pushname
                                  ?.split(' ')
                                  ?.slice(0, 2)
                                  ?.map((word) => word[0].toUpperCase())
                                  ?.join('')
                              : 'AS'}
                          </Text>
                        </Box>
                        <Box
                          p={4}
                          pt={3}
                          pb={2}
                          width={'100%'}
                          borderWidth="1px"
                          borderRadius="3xl"
                          borderBottomLeftRadius={'none'}
                          boxShadow="md"
                          bg={'white'}
                          color={'black'}
                          fontSize="sm"
                        >
                          <Text fontWeight="bold" m={0}>
                            {message?.pushname || 'Asistente'}:
                          </Text>
                          {
                            message?.quoted_message_data && (
                              <Box p={2} mt={2} bg={bgColor} borderRadius={'md'} color={'gray.00'}>
                                <Text m={0} fontSize={'xs'} opacity={1} fontWeight={'bold'}>
                                  {message?.quoted_message_data?.pushname}
                                </Text>

                                <Text m={0} fontSize={'sm'} fontWeight={'normal'}>
                                  {message?.quoted_message_data?.body}
                                </Text>
                              </Box>
                            ) //? Display quoted message
                          }
                          {(message?.media || message?.image) && (
                            <button
                              onClick={() => {
                                setSelectedImage(message?.media || message?.image);
                              }}
                            >
                              <Image src={message?.media || message?.image} alt="Image" maxH="64px" cursor="pointer" />
                            </button>
                          )}
                          {(message?.body || message?.caption) && (
                            <Flex
                              alignItems={'end'}
                              justifyContent="space-between"
                              p={0}
                              py={1}
                              m={0}
                              gap={2}
                              width={'100%'}
                            >
                              <Text m={0} color="gray.900" fontWeight="normal" pb={2}>
                                {message?.body || textCaption}{' '}
                                {textCaption && (
                                  <ChakraLink
                                    as={ReactRouterLink}
                                    to={urlMessage}
                                    color="green"
                                    target="_blank"
                                    textDecoration={'underline'}
                                  >
                                    Link
                                  </ChakraLink>
                                )}
                              </Text>

                              {message.time && (
                                <Text
                                  fontSize="8px"
                                  m={0}
                                  p={0}
                                  color="gray.500"
                                  fontWeight={'bold'}
                                  textAlign={'right'}
                                >
                                  {new Date(message.time).toLocaleTimeString([], {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                  })}
                                </Text>
                              )}
                            </Flex>
                          )}
                        </Box>
                      </Flex>
                    );
                  })}
                </Box>
              );
            })}
            <Box ref={lastMessageRef} mt={5} />
          </Box>
        ) : (
          <Box display={'flex'} alignItems={'center'} justifyContent="center" w="100%" h="100%">
            <Text fontSize={20} fontWeight="normal" color="green.400">
              {'No hay mensajes'}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ChatList;
