import React from 'react';

import { Box, Button, Flex, Text, useColorModeValue } from '@chakra-ui/react';

const MessageChatsList = ({ chat, index, getCurrentChat, activeChat }) => {
  const greenOpacity = useColorModeValue('rgba(162, 212, 111, 0.2)');
  return (
    <Button
      key={index}
      borderBottom={'1px'}
      borderColor={'gray.200'}
      borderRadius={0}
      maxHeight="70px"
      height={'70px'}
      p={4}
      px={4}
      m={0}
      color="gray.900"
      bg={activeChat ? greenOpacity : 'white'}
      onClick={() => getCurrentChat(chat.chat_id)}
      _hover={{
        color: 'blue.500',
        cursor: 'pointer',
        paddingBottom: 3,
      }}
      _active={{
        bg: 'cyan.800',
        color: 'white',
      }}
      w="100%"
    >
      <Box
        maxW="100%"
        width={'100%'}
        display={'flex'}
        justifyContent={'start'}
        flexDirection="column"
        alignItems="start"
        textWrap="nowrap"
      >
        <Flex alignItems={'center'} gap={2} w="100%">
          {!chat.read && <Box w={2} h={2} bg="green.300" rounded={10} />}
          <Text m={0} fontWeight="bold" isTruncated>
            {chat?.chat_name}
          </Text>
        </Flex>
        <Text isTruncated w="100%" pb={3} m={0} pt={3} textAlign={'start'} fontWeight={'normal'}>
          {chat.body || 'Imagen'}
        </Text>
      </Box>
    </Button>
  );
};

export default MessageChatsList;
